.dataTables_length{
    float: left;
}
.dataTables_filter{
    float: right;
}
.dataTables_length, .dataTables_filter{
    margin-bottom: 10px;
}
table.datatable{
    clear: both;
}

td.details-control {
    background: url('img/details_open.png') no-repeat center center;
    cursor: pointer;
}
tr.details td.details-control {
    background: url('img/details_close.png') no-repeat center center;
}

.container.body-container > div.ui.container{ width: 90% !important;}

.float-right{
    float: right;
}

.float-left{
    float: left;
}
.no-padding {
    padding: 0 !important;
}

.pointer{
    cursor: pointer;
}

.dt-button:not(.basic):not(.inverted)>.button, .dt-button>.dt-button:not(.basic):not(.inverted) {
    -webkit-box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset;
}

/*.dt-button , .dt-button {*/
    /*background: transparent none!important;*/
    /*color: rgba(0,0,0,.6)!important;*/
    /*font-weight: 400;*/
    /*border-radius: .28571429rem;*/
    /*text-transform: none;*/
    /*text-shadow: none!important;*/
    /*box-shadow: none!important;*/
    /*border-left: none;*/
    /*margin-left: 0;*/
    /*border-top-left-radius: .28571429rem;*/
    /*border-bottom-left-radius: .28571429rem;*/
    /*padding: 11px 22px;*/
    /*cursor: pointer;*/
    /*border: 1px solid rgba(34,36,38,.15);*/
/*}*/

.dz-preview{
    display: none;
}

#filesTable .download-button .content{
    font-size: 12px;
}

#real_name.error, #realNameAnnex.error{
    border-color: red;
}

.success-message p{
    color: green;
    font-size: 13px;
}

.success-message .header{
    word-break: break-all;
}


.tab.segment .dt-button.disabled{
    opacity: 0.5;
}

#current-insurance-section{
    padding: 30px 0px;
}

#current-insurance-section .add-insurance{
    color: rgb(33, 150, 243);
    padding-top: 100px;
    font-size: 22px;
    border: 3px rgb(33, 150, 243) dashed;
    text-align: center;
    height: 282px;
    border-radius: 5px;
    cursor: pointer;
    margin: 25px 0px;
}

#current-insurance-section .add-insurance.added{
    padding-top: 0;
    border: 3px rgba(0,0,0,.87) solid !important;
    color: #000 !important;
    cursor: default;
    background-color: #fff !important;
}


#current-insurance-section .add-insurance:hover {
    background-color: rgb(0, 116, 208);
    border: none;
    color: #fff;
}

.delete-insurance{
    position: absolute;
    right: -15px;
    top: -13px;
    background: red;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;

}

.edit-insurance{
    position: absolute;
    right: 16px;
    top: -13px;
    background: #008000;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
}

.edit-insurance i{
    left: 2px;
    position: relative;
}


.delete-insurance i{
    left: 2px;
    position: relative;
}

#current-insurance-section .raised.segment{
    box-shadow: none;
    border: 0;
}

.insurance-arrow{
    position: absolute;
    bottom: -46px;
    width: 80px;
    margin: 0 auto;
    font-size: 25px;
    left: 118px;
    color: #fff;
}

.insurance-arrow .left-arrow{
    float: left;
    background: #000;
    cursor: pointer;
    padding-top: 4px;
}

.insurance-arrow .right-arrow{
    float: right;
    background: #000;
    cursor: pointer;
    padding-top: 4px;
}

/*#bills-table input{
    max-width: 80%;
}

#bills-table .money input{
    max-width: 55%;
}

#bills-table tbody tr td:first-child{
    max-width: 150px;
}

#bills-table tbody tr td:first-child + td{
    max-width: 150px;
}

#bills-table tbody tr td:first-child + td input{
    max-width: 70%;
}

#bills-table select{
    max-width: 50%;
}*/

.btn-green.add-bill{
    color: green;
    font-size: 22px;
    cursor: pointer;
    position: relative;
    top: 1px;
}

.btn-green.add-bill:hover{
    color: #4183c4;
}

.note-edit {margin-left: 5px;}
.text-wrap {white-space: normal;}

#billDOS{
    position: relative;
}

.btn-red{
    color: #FF0000;
}

.btn-green{
    color: #008000;
}

.modifier-container-block input{
    width: 55px !important;
}

.modifier-container-block a{
    display: block;
    float: left;
    margin-top: 8px;
    cursor: pointer;
}

.modifier-container-block a.btn-red{
    margin-left: 7px;
}

.diagnosis-row label{
    top: -3px;
    position: relative;
}

.equils-height {
    align-items: stretch;
}
.equils-height > .field > .card {
    height: 100%;
}

ui.cheeckbox.diagnosis-pointer-block,
.diagnosis-pointer-block{
    display: inline-block;
    min-width: 31%;
}

#addressList a i{
    color: red;
    margin-left: 10px;
    margin-top: 6px;
    font-size: 20px;
    cursor: pointer;
}

#addressList input{
    width: 85%;
}

/*#bills-table .results{
     display: none;
     z-index: 9999;
 }

#bills-table .results a{
    cursor: pointer;
    display: block;
    overflow: hidden;
    font-size: 1em;
    padding: 15px;
    color: rgba(0,0,0,.87);
    line-height: 1.33;
    border-bottom: 1px solid rgba(34,36,38,.1);
}

#billForm .results{
    display: none;
    z-index: 9999;
    max-height: 162px;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #eee;
    position: absolute;
    background: #fff;
    width: 95%;
}

#billForm .results.active{
    display: block;
}

#billForm .results a{
    cursor: pointer;
    display: block;
    overflow: hidden;
    font-size: 1em;
    padding: 15px;
    color: rgba(0,0,0,.87);
    line-height: 1.33;
    border-bottom: 1px solid rgba(34,36,38,.1);
}

#arbitrationForm .results{
    display: none;
    z-index: 9999;
    max-height: 162px;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #eee;
    position: absolute;
    background: #fff;
    width: 95%;
}

#arbitrationForm .results.active{
    display: block;
}

#arbitrationForm .results a{
    cursor: pointer;
    display: block;
    overflow: hidden;
    font-size: 1em;
    padding: 10px;
    color: rgba(0,0,0,.87);
    line-height: 1.33;
    border-bottom: 1px solid rgba(34,36,38,.1);
}*/

#arbitrationForm .message.empty {
    padding: 15px;
}



#arbitrationForm .message.empty .header{
    font-weight: bold;
}

#arbitrationForm .message.empty .description{
    font-size: 12px;
}

#bills-table .message.empty {
    padding: 15px;
}



#bills-table .message.empty .header{
    font-weight: bold;
}

#bills-table .message.empty .description{
    font-size: 12px;
}

#bills-table .results a:hover{
    background: #eee;
}

#bills-table .results.active{
    display: block;
    position: absolute;
    width: 300px;
    background: #fff;
    border: 1px solid rgba(34,36,38,.1);
    border-radius: 2px;
    top: 46px;
    z-index: 5;
}

#payListItems{
    padding: 18px;
    background: #eee;
    border: 1px solid rgba(34,36,38,.1);
    border-radius: 2px;
    position: absolute;
    z-index: 99;
    display: none;
    width: 500px;
}

#payListItems input{
    width: 48%;
    float: left;
    margin-right: 2%;
}

#payListItems select{
    width: 48%;
}

#payListItems.active{
    display: block;
}


#showItemList{
    margin-left: 60px;
    color: #000;
    display: none;
}

#showItemList.active{
    display: inline-block;
}

/*#addInsurances{
    overflow-y: scroll;
}*/

#current-insurance-section{
    width: 1000px;
    margin: 0 auto;
}

.units-select-box{
    width: 50% !important;
    min-height: 20px !important;
}

#formMergeModal{
    position: relative;
}

#sortable li{
    border: 1px solid #cccccc;
    background: #f6f6f6;
    font-weight: bold;
    color: #1c94c4;
    padding: 10px 15px;
    margin-top: 2px;
    cursor: pointer;
}

#filesTable td {
    max-width: 50px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#bills-table.payment td {
    max-width: 150px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#bills-table.payment input{
    max-width: 70%;
}

.modal #bills-table.payment input{
    max-width: 60%;
}

#arbitrationForm .ms-container{
    width: 100%;
}

#arbitrationForm .ms-list{
    height: 300px;
    overflow-x: hidden;
}

.delete-signature{
    cursor: pointer;
}


.loadspinner {
    position: absolute;
    left: 50%;
    top: 50%;
    height:60px;
    width:60px;
    margin:0px auto;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(0,174,239,.15);
    border-right:6px solid rgba(0,174,239,.15);
    border-bottom:6px solid rgba(0,174,239,.15);
    border-top:6px solid rgba(0,174,239,.8);
    border-radius:100%;
}

#patientSearch{
    position: relative;
}

.mt-22 {
    margin-top: 22px !important;
}
.mt-24{
    margin-top: 24px !important;
}

@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}

.label-info-multiple{
    margin: 5px;
    color: #fff;
    padding: 5px;
    font-size: 13px;
    background-color: #0044cc;
    border-radius: 5px;
}

.p-t-10{
    padding-top: 10px;
}

#multipleDays .content{
    height: 450px;
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
    z-index: 2;
}

#datepickerMiltiple table{
    width: 250px;
    min-height: 250px;
}

#datepickerMiltiple table thead tr:last-child th{
    padding: 5px 0px;
}

#datepickerMiltiple table thead th.prev,
#datepickerMiltiple table thead th.next{
    font-size: 22px;
}

#datepickerMiltiple table .datepicker-switch{
    font-size: 15px;
}

.add-items-buttons{
    float: left;
}


#addPracticeAddressButton{
    margin-left: 5px;
    margin-top: 1px;
}
.insurance-arrow .arrow i.icon {
    margin: 0;
}

#uploadEfiles{
    height: 100%;
    z-index: 9998;
    cursor: pointer;
    background: url('img/paperclip.png') no-repeat center center;
    background-size: 50px;
}

#uploadEfiles .inverted.dimmer{
    background-color: #fff;
}

#delete_modal .actions{
    text-align: center;
}
#main-container-default {
    display: inline-block;
    margin-top: 103px;
    width: 100%;
}
.margin-no {
    margin: 0 !important;
}

.ui.button.micro{
    padding: 0.485714em 0.485714em 0.485714em !important;
    font-size: 0.8rem !important;
}

.secondary_menu .ui.labeled.button a {
    border: 0 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.secondary_menu .ui.labeled.button a.button{
    padding-left: 0 !important;
}
.secondary_menu .ui.labeled.button a.label
{
    padding-left:5px !important;

}
.secondary_menu .ui.labeled.button a.button,
.secondary_menu .ui.labeled.button a.label
{
    padding-right: 0 !important;

}

.dt-empty{
    background-color: #ededed;
}

/*right tags*/

.ui.tag.label.right, .ui.tag.labels.right .label {
    margin-right: 1.5em;
    position: relative;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: .28571429rem 0 0 .28571429rem;
    -webkit-transition: none;
    transition: none;
}

.ui.tag.label.right:before, .ui.tag.labels.right .label:before {
    position: absolute;
    -webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
    top: 50%;
    left: calc(100% - 1.5em);
    content: '';
    background-color: inherit;
    background-image: none;
    width: 1.56em;
    height: 1.56em;
    -webkit-transition: none;
    transition: none;
}

.ui.tag.label.right:after, .ui.tag.labels.right .label:after {
    position: absolute;
    content: '';
    top: 50%;
    left: calc(100% - .5em);
    margin-top: -.25em;
    background-color: #fff!important;
    width: .5em;
    height: .5em;
    -webkit-box-shadow: 0 -1px 1px 0 rgba(0,0,0,.3);
    box-shadow: 0 -1px 1px 0 rgba(0,0,0,.3);
    border-radius: 500rem;
}
/*right tags END*/

/*forms*/
.formContentBlock{
    /*margin-bottom: 60px;*/
}
.formActionBlock{
    /*position: absolute; bottom: 0;*/
}
/*forms END*/

label.required{
    color: #9f3a38 !important;
}
label.required:after{
    color: #9f3a38;
    content: '*';
    margin-left: 5px;
}

.field div label.required {
    color: #000 !important;
}

.field div label.required:after {
    content: '';
    margin-left: 0px;
}

#overview .item.green{
    color: #0ea432 !important;
}

#overview .inverted.menu{
    z-index: 9999;
}


#overview .item{
    font-size: 13px;
    padding: 10px;
}


#overview .dollar.sign{
    margin-right: 0px;
}


.w-100{
    width: 100% !important;
}

.radio.fs-10{
    font-size: 10px !important;
    font-weight: 800;
    line-height: 18px;
}

#newBillNoteList{
    font-size: 11px;
}
.ui.list .list > .item .description, .ui.list > .item .description.note-desc-deleted,
.note-desc-deleted {
    color: #cecece;
    text-decoration: line-through;
}
.note-user-date {
    margin-right: 5px;
}

.ui.labeled.input.ui-calendar-input .ui.label.calendar {
    padding: 0 !important;
}
.ui.labeled.input.ui-calendar-input .ui.label.calendar > .ui.button {
    /*height: 100% !important;*/
    margin: 0 !important;
}
.form-bacground{
    padding: 10px;
    background-color: #f7f6f6;
}

.modal-bill-note-block{
    max-height: 220px;
    overflow: auto;
}

#loaderBlock{
    z-index: 99999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
}

#reportPrint {
    position: absolute;
    right: 1%;
    top: 2px;
    z-index: 9999;
}
.patientFilter {
    padding: 0 10px !important;
}

.ui.loader.basic-loader:before {
    border-color: rgba(0,0,0,.1) !important;
}
.ui.loader.basic-loader:after {
    border-color: #767676 transparent transparent !important;
}

pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

#newBillNoteList .header.left{
    float: left;
    margin-right: 10px;
}

/*.edit-bill-notes{*/
    /*display: none;*/
/*}*/

#delete_modal.second{
    background-color: rgba(0,0,0,.85) !important;
}
.ui.dt-label-inline {
    float: left;
    margin: 1px 2px 1px 0;
    position: relative;
}

#add_template_modal .formActionBlock{
    display: none;
}

#practiceTemplateDataTable_filter{
    width: 185px;
}

#practiceTemplateDataTable_wrapper .filter-row{
    height: 50px;
}

#practDocDataTable_filter{
    width: 185px;
}

#practDocDataTable_wrapper .filter-row{
    height: 50px;
}
.toast-center-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.dt-actions {
    float: left;
    margin-right: 1rem;
}
.dt-gear-modal .ms-container {
    width: 100%;
}
#verification-request-modal #verification_request_note,
#verification-request-modal-multiple #verification_request_note {
    height: calc(100% - 30px);
}
.ui.micro.menu {
    font-size: 0.66rem;
}
.datatable-small .ui.button.mini.icon.loading:before,
.datatable-small .ui.button.mini.icon.loading:after {
    left: 40% !important;
    top: 40% !important;
}

#doctor-calendar-modal .selected-day{
    margin-bottom: 5px;
}

.clear-date-input-btn {
    cursor: pointer;
}
.clear-date-input-btn > .icon {
    opacity: 1 !important;
    margin: 0 !important;
}
.clear-dropdown-btn {
    align-items: center;
    cursor: pointer;
    display: flex;
}
.clear-dropdown-btn > .icon {
    opacity: 1 !important;
    margin: 0 !important;
}
.ui.labeled.input.left-labeled .ui.dropdown.selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.ui.labeled.input.right-labeled .ui.dropdown.selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
#vr-answer-save-print-btn {
    position: absolute;
    right: 8px;
    top: 6px;
    z-index: 9999;
}
#vr-answer-save-btn {
    position: absolute;
    right: 8px;
    top: 38px;
    z-index: 9999;
}


#doctor-calendar-modal .disabled.disabled-date{
    color: #fff;
    background-color: crimson;
}
.ui.table .ui.dropdown.dt-select {
    min-height: auto !important;
}

.ui.input .ui.dropdown input.search {
    width: auto !important;
}
/*.search-diagnosis-block:after{*/
   /*content: 'Loading';*/
/*}*/

@keyframes spinner {
    to {transform: rotate(360deg);}
}

.search-diagnosis-block.loading:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 5%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    animation: spinner .6s linear infinite;
    z-index: 99;
}

#search-insurance-result-address {
    line-height: 30px;
}
.insurance-search .results {
    width: 100% !important;
}
.ui.button i.fas {
    width: 1.18em;
}


#modal-case-efile-tag-filters .modal-case-efile-tag-filter-button {
    margin-bottom: 0.5rem;
    width: 100%;
}
#modal-case-efile-tag-filters .modal-case-efile-tag-filter-button:hover .button.basic {
    -webkit-box-shadow: 0 0 0 1px #2185d0 inset!important;
    box-shadow: 0 0 0 1px #2185d0 inset!important;
    color: #2185d0!important;
}
#modal-case-efile-tag-filters .modal-case-efile-tag-filter-button:hover .label.basic {
    background: none #fff!important;
    color: #2185d0!important;
    border-color: #2185d0!important;
}
#modal-case-efile-tag-filters .modal-case-efile-tag-filter-button .button.basic {
    text-align: left;
    width: 100%;
}
#modal-case-efile-tag-filters .modal-case-efile-tag-filter-button.active .button.basic {
    -webkit-box-shadow: 0 0 0 1px #2185d0 inset!important;
    box-shadow: 0 0 0 1px #2185d0 inset!important;
    color: #2185d0!important;
}
#modal-case-efile-tag-filters .modal-case-efile-tag-filter-button.active .label.basic {
    background: none #fff!important;
    color: #2185d0!important;
    border-color: #2185d0!important;
}
#modal-case-efiles-dt_filter {
    width: 100%;
}
#include-efiles-container .label {
    margin-bottom: 0.3em;
}

#mm_top-search .results {
    width: 21em;
    max-height: 227px;
    overflow: auto;
}

.display-none {
    display: none!important;
}

.text-menu-lvl3 a {
    width: 100%;
}

.field.hidden{
    display: none;
}

.field.hidden.show{
    display: block;
}

#billNoteStatus{
    position: absolute;
    left: 550px;
    z-index: 10;
    top: -1px;
    width: 500px;
}

#billNoteStatus label{
    font-size: 9px;
    margin: 0;
}

.arb-status-multiple-form-block{
    display: none;
}

.arb-status-multiple-form-block.show{
    display: block;
}

.locationRowBlock{
    position: relative;
}

.locationRowBlock p{
    position: absolute;
    top: 1px;
    background: #fff;
    display: none;
    width: 300%;
    z-index: 9999;
    border: 1px solid #eee;
}

.locationRowBlock:hover  p{
    display: block;
}

.container.body-container div.container.container-fluid-98,
.container.container-fluid-98{
    width: 98%!important;
}

.ui.button.small-mini {
    font-size: .75571429rem;
    padding: .75571429em .75571429em .75571429em;
}

.billChangeStatusRow{
    overflow: visible !important;
}

.arb-status-multiple-form-block-bill-items{
    display: none;
}

.arb-status-multiple-form-block-bill-items.show{
    display: block;
}
#rx-drop-area {
    height: 62px;
    outline-width: 0.5px;
    outline-color: lightgrey;
    padding: 0;
    z-index: 2;
}
#rx-upload-file {
    cursor: pointer;
    height: 100%;
    margin: 0;
    z-index: 9998;
}
#rx-upload-file .ui.header {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0;
    position: relative;
    width: 100%;
    z-index: -1;
}

.mainmenu_rabbitlogo path{
    fill: #ccc;
}
.mainmenu_rabbitlogo{
    width: 14px !important;
    margin: 0 5px 5px 0 !important;
}

.tabular.menu .item.hidden{
    display: none;
}


.hidden-row {
    display: none;
}

.relative-calendar {
    position: relative;
}

#pdf #spinner{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
}

.calendar-dimmer{
    position: absolute;
    left: 50%;
    top: 400px;
    width: 20px;
    background-color: transparent !important;
}


.calendar-dimmer .dimmer{
    width: 20px;
    background-color: transparent !important;
}

.calendar-block{
    width: 1300px;
    margin: 0 auto;
    max-width: 98%;
    max-height: 500px;
}

#mfAppointmentCreateMenu, #facilityAppointmentBlock, #facilityAppointmentCreate{
    margin-bottom: 5px;
}

#facilityAppointmentCreate button, #facilityAppointmentBlock button{
    margin: 0 1px;
}

#mfAppointmentCreateMenu #facilityAppointmentBlock{
    overflow-y: hidden;
    overflow-x: auto;
}

#facilityAppointmentBlock button{
    background-color: #21ba45;
    color: #fff;
    text-shadow: none;
    background-image: none;
    font-size: 10px;
    padding: 8px;
}

#facilityAppointmentCreate button.active, #facilityAppointmentBlock button.active{
    color: #fff;
    background-color: #2C3E50;
    border-color: #2C3E50;
}

#facilityAppointmentCreate button.active span{
    background-color: #fff;
}

#facilityAppointmentCreate button{
    text-align: center;
    width: 25%;
    padding: 3px 3px;
    max-width: 25%;
}

#facilityAppointmentCreate .ui.buttons{
    width: 100%;
    padding-bottom: 6px;
}

#facilityAppointmentCreate button.plus{
    width: 50px !important;
    max-width: 50px !important;
    min-height: 35px;
}

#facilityAppointmentCreate button.plus i{
    margin: 0;
}

#facilityAppointmentCreate span{
    background-color: #000;
    height: 1px;
    width: 80%;
    display: block;
    margin: 1px auto;
}

.opacity-25{
    opacity: 0.25;
}

button.icon.edit-segment-modal-btn {
    position: absolute;
    right: 10px;
    top: 10px;
}

#create-multiple-appointment-modal .ui.blue.label{
    margin-bottom:2px;
}

#datepicker-appointment-three-calendar .ui-datepicker-multi{
    max-width: 100%;
    position: relative;
}

#datepicker-appointment-three-calendar .ui-datepicker-group{
    width: 100%;
}

#datepicker-appointment-three-calendar .ui-datepicker .ui-datepicker-header{
    position: inherit;
}

#datepicker-appointment-three-calendar .ui-datepicker-calendar td.available a{
    background-color: #0e8c8c;
    color: #fff;
}

#datepicker-appointment-three-calendar .ui-state-highlight {
     border: 1px solid #c5c5c5;
     background: #f6f6f6;
     font-weight: normal;
     color: #454545;
 }

#datepicker-appointment-three-calendar .ui-datepicker-current-day .ui-state-active{
    border: 1px solid #003eff !important;
    background: #007fff !important;
    font-weight: normal !important;
    color: #fff !important;
}

.facility-tag-link{
    margin-bottom: 10px !important;
}


#modal-edit-payment #bills-table.payment td{
    overflow: inherit;
}


.reason-text-class.red{
    color: red;
}

#agenda .created{
    background: #e5c0c0;
}

#agenda .confirmed{
    background: #00ff55;
}


#agenda .arrived{
    background: #6EFF90;
}

#agenda .no_show{
    background: #ed4609;
}

#agenda .cancelled{
    background: #f20066;
}

#agenda .re_scheduled{
    background: #1F1FFF;
}

#agenda .being_seen{
    background: #C4FFDE;
}

#agenda .completed{
    background: #fff500;
}
