@import "~semantic-ui/dist/semantic.min.css";
@import "~semantic-ui-calendar/dist/calendar.min.css";
@import "~toastr/build/toastr.min.css";
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import "main.css";
@import "default.css";
@import "~jquery-jsonview/dist/jquery.jsonview.css";
/*@import "~datatables.net-dt/css/jquery.dataTables.min.css";*/
